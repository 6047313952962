import { Injectable, WritableSignal, signal } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppTrackingStatusResponse, AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { SignOut } from 'src/app/auth/store';

@Injectable({
  providedIn: 'root',
})
export class AppTrackingService {
  status$: WritableSignal<'authorized' | 'denied' | 'notDetermined' | 'restricted'> = signal('notDetermined');

  constructor() {
    if (Capacitor.getPlatform() !== 'ios') {
      this.status$.set('authorized');
    }
  }

  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();
    this.status$.set(response.status);

    return response;
  }

  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();
    this.status$.set(response.status);

    return response;
  }
}
