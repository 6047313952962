import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Html5PlayerVars } from '@teamfoster/sdk/html5-video-player';
import { ImagAnchor, Image } from '@teamfoster/sdk/image';
import { Address } from 'src/app/addresses/models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() text?: string;
  @Input() address?: Address;
  @Input() cssClass: string = '';
  @Input() image?: Image;
  @Input() imageUrl: string | null = 'https://www.rova.nl/media/_DSF1398.jpg';
  @Input() imageAnchor?: ImagAnchor = 'middlecenter';
  @Input() hideImageMobile: boolean = false;
}
