<div class="grid-overlay">
  <div class="container">
    <div class="row grid-showcase">
      <div *ngFor="let number of gridColumns" class="col-1">
        <div class="grid-showcase__inner">
          {{ number + 1 }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="breakpoint-util">
  <ul class="breakpoint-util-list">
    <li class="breakpoint-util-list__item" *ngFor="let bp of gridBreakpoints; let i = index" [ngClass]="getBpClass(bp, i)">{{ bp }}</li>
  </ul>
</div>
