import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as RouterActions from '../actions/router.action';

import { tap, map } from 'rxjs/operators';

@Injectable()
export class RouterEffects {
  constructor(private actions$: Actions, private router: Router, private location: Location) {}

  navigate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.Go),
        tap(({ path, queryParams, extras }) => {
          this.router.navigate(path, { queryParams, ...extras });
        })
      );
    },
    { dispatch: false }
  );

  navigateBack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.Back),
        tap(() => this.location.back())
      );
    },
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.Forward),
        tap(() => this.location.forward())
      );
    },
    { dispatch: false }
  );
}
