import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { AppTrackingService } from '../services';
import { Capacitor } from '@capacitor/core';
import { Go } from 'src/app/store';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appDynamicContent]',
})
export class CookieListenerDirective {
  @Output() cookieButton = new EventEmitter<void>();

  @HostListener('click', ['$event']) async onClick(e: any) {
    if (e.target.classList.contains('fstr-cookie-btn')) {
      e.preventDefault();
      e.stopPropagation();

      if (Capacitor.getPlatform() === 'ios') {
        const status = await this.appTracking.getStatus();
        if (status.status !== 'authorized') {
          this.store.dispatch(Go({ path: ['/instellingen'] }));
          return;
        }
      }

      this.store.dispatch(ShowCookieSettings());
      this.cookieButton.emit();
    }
  }

  constructor(
    private el: ElementRef,
    private store: Store,
    private appTracking: AppTrackingService
  ) {}
}
