<header class="section-inset-y header">
  <div class="container header__inner pt-topbar">
    <div class="row header__inner__row">
      <main class="col-md-6 col-xl-5 header__main">
        <h2 class="text-primary h6 fw-semi font-secondary header__subtitle mb-1" *ngIf="subtitle">{{ subtitle }}</h2>
        <h1 class="text-secondary h3 mt-1" [class.mb-0]="!text" *ngIf="!address && title">{{ title }}</h1>
        <h1 class="text-secondary" [ngClass]="address.street.length > 20 ? 'h4' : 'h3'" [class.mb-0]="!text" *ngIf="!title && address">
          {{ address.street }} {{ address.houseNumber }}{{ address.addition }},
          <!-- {{ address.postalCodeFull }}  -->
          {{ address.city | titlecase }}
        </h1>

        <p class="text-max-width-xxs" *ngIf="text">{{ text }}</p>
      </main>

      <!-- offset-md-1 offset-lg-2 -->
      <aside class="col-md-6 col-xl-7 d-block d-md-flex">
        <ng-content [aside]></ng-content>
      </aside>
    </div>
    <figure class="header__figure" *ngIf="image || imageUrl" [ngClass]="hideImageMobile ? 'd-none d-md-block' : 'd-block'">
      <div class="header__figure__inner">
        <fstr-image
          [imageSizes]="[800]"
          [alt]="'mijn rova afbeelding'"
          [image]="image || { url: imageUrl, anchor: imageAnchor }"
          [loading]="'eager'"
          [width]="750"
          [height]="500"
        ></fstr-image>
      </div>
    </figure>
  </div>

  <ng-content select="[main]"></ng-content>
</header>
